/**
 * Created by ApolloLogix on 2022-11-18.
 */


class slider1 {
    constructor(value_class, slider_tag, value_init, function_to_run, array_to_change, index_array, index_slider) {
        console.log('class slider1 ')
        this.value = $(`.${value_class}`);
        this.slider = $(`#${slider_tag}`);
        this.slider.val(value_init)
        this.slider.html(value_init); // show value
        this.slider.on('input change', () => {
            this.value.html(this.slider.val());
            array_to_change[index_array][index_slider] = this.slider.val()
            function_to_run()
        })
    }

    get_value() {
        return this.slider.val()
    }

}

// class for multiple sliders, for example a group of sliders for controlling color
class slidersObj {
    constructor(values_class, sliders_tag, values_init, function_to_run, array_to_change, index_array) {
        console.log('array_to_change:', array_to_change, index_array)
        this.sliders = []
        for (const index_slider in values_init) {
            this.sliders.push(new slider1(values_class[index_slider], sliders_tag[index_slider], values_init[index_slider], function_to_run, array_to_change, index_array, index_slider))
        }
    }
}
